export const SAMPLE_COLOURS = [
  '#BDE4A8',
  '#A09EBB',
  '#5D737E',
  '#494949',
  '#FF5D73',
  '#A37871',
  '#E5F77D',
  '#9BC1BC',
  '#C94277',
  '#82D173',
  '#4C2C69',
  '#38182F',
  '#EEE1B3',
  '#1B98E0',
  '#A4303F',
  '#FFD131',
  '#616283'
];
