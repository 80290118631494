import { isEqual, random, sample, sampleSize, uniqWith } from 'lodash';
import { paramCase } from 'change-case';
import initial from './initial';
import { SAMPLE_COLOURS } from './colours';
// ---- USED MAINLY FOR TEMPLATING FROM THE CORE APP EDITOR

const DEFAULT_PRODUCT_NAME = 'Product Name';
const DEFAULT_PRODUCT_DESCRIPTION =
  'A description of your product. Give as much detail as possible that is required for your customer to make an informed choice';

const PRODUCT_IMAGES = [
  'https://media.shoppymonkey.com/images/themes/default/example-product-1.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-2.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-3.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-4.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-5.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-6.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-7.png',
  'https://media.shoppymonkey.com/images/themes/default/example-product-8.png'
];

const createProducts = (size) =>
  [...Array(size)].map((_, index) => {
    const productImage = PRODUCT_IMAGES[index % PRODUCT_IMAGES.length];
    const price = sample(['13.54', '7.35', '11.22', '18.99']);
    const priceSale = sample(['33.12', '55.22', '29.10', '38.99']);
    const priceSaleChange = sample(['34', '12', '22', '13']);
    return {
      id: index,
      name: DEFAULT_PRODUCT_NAME,
      resource: paramCase(DEFAULT_PRODUCT_NAME),
      description: DEFAULT_PRODUCT_DESCRIPTION,
      sku: 'exampe-product-sku',
      status: sample(['SALE', 'NEW', 'OUT_OF_STOCK', null]),
      quantity: random(1, 20),
      images: [productImage],
      image: productImage,
      colours: sampleSize(SAMPLE_COLOURS, random(2, 8)),
      price: {
        amount: price,
        amountInMinorUnits: price.replace('.', '')
      },
      priceSale: {
        amount: priceSale,
        amountInMinorUnits: priceSale.replace('.', '')
      },
      priceSaleChange: {
        amount: priceSaleChange,
        amountInMinorUnits: `${priceSaleChange}00`,
        percent: sample(['9', '12', '44', '98'])
      },
      variants: uniqWith(
        [...Array(16)].map((_, index) => {
          const vPrice = sample(['13.54', '7.35', '11.22', '18.99']);
          const vPriceSale = sample(['33.12', '55.22', '29.10', '38.99']);

          return {
            id: `abcde-${index}-${random(1, 20)}`,
            sku: 'example-sku-123-abc',
            colour: sample(['Red', 'Blue', 'Pink', 'Purple']),
            size: sample(['Small', 'Medium', 'Large', 'Extra Large']),
            quantity: random(1, 20),
            price: {
              amount: vPrice,
              amountInMinorUnits: vPrice.replace('.', '')
            },
            priceSale: {
              amount: vPriceSale,
              amountInMinorUnits: vPriceSale.replace('.', '')
            },
            priceSaleChange: {
              amount: priceSaleChange,
              amountInMinorUnits: `${priceSaleChange}00`,
              percent: sample(['9', '12', '44', '98'])
            }
          };
        }),
        (a, b) => a.size === b.size && a.colour === b.colour
      )
    };
  });

const createProductList = (size) =>
  [...Array(size)].map((_, index) => {
    const productImage = PRODUCT_IMAGES[index % PRODUCT_IMAGES.length];
    const price = sample(['13.54', '7.35', '11.22', '18.99']);
    const priceSale = sample(['33.12', '55.22', '29.10', '38.99']);
    const priceSaleChange = sample(['34', '12', '22', '13']);
    return {
      id: index,
      name: DEFAULT_PRODUCT_NAME,
      resource: paramCase(DEFAULT_PRODUCT_NAME),
      status: sample(['SALE', 'NEW', 'OUT_OF_STOCK', null]),
      quantity: random(1, 100),
      image: productImage,
      colours: index % 3 === 0 ? null : sampleSize(SAMPLE_COLOURS, random(2, 8)),
      price: {
        amount: price,
        amountInMinorUnits: price.replace('.', '')
      },
      priceSale: {
        amount: priceSale,
        amountInMinorUnits: priceSale.replace('.', '')
      },
      priceSaleChange: {
        amount: priceSaleChange,
        amountInMinorUnits: `${priceSaleChange}00`,
        percent: sample(['9', '12', '44', '98'])
      }
    };
  });

export const DEFAULT_PRODUCT = createProducts(1)[0];

export const getDefaultProducts = (size) => createProducts(size);

export const getDefaultPagedProducts = (size) => {
  const products = createProductList(size);
  return {
    ...initial.pagableData,
    data: products,
    total: products.length,
    filters: [
      { status: 'New' },
      { status: 'Out of date' },
      { colours: 'Red' },
      { colours: 'Purple' },
      { priceMin: '23.34' },
      { priceMax: '623.34' }
    ]
  };
};

export const getDefaultProductFilters = () => {
  const colours = ['Pink', 'Red', 'Purple', 'Orange', 'Blue', 'White', 'Black'].map((colour) => {
    return {
      id: colour,
      name: colour,
      count: random(1, 100, false)
    };
  });
  const sizes = ['Extra Large', 'Large', 'Medium', 'Small', 'Extra Small'].map((size) => {
    return {
      id: size,
      name: size,
      count: random(1, 100, false)
    };
  });
  const status = ['New', 'Sale', 'Out Of Stock'].map((status) => {
    return {
      id: status,
      name: status,
      count: random(1, 100, false)
    };
  });
  const min = random(1, 1000, true);
  const max = (min + random(1, 200, true)).toFixed(2);
  const filters = {
    status: {
      options: uniqWith(status, (o1, o2) => isEqual(paramCase(o1.name), paramCase(o2.name)))
    },
    sizes: {
      options: uniqWith(sizes, (o1, o2) => isEqual(paramCase(o1.name), paramCase(o2.name)))
    },
    colours: {
      options: uniqWith(colours, (o1, o2) => isEqual(paramCase(o1.name), paramCase(o2.name)))
    },
    prices: {
      min: {
        amount: min.toFixed(2),
        amountInMinorUnits: `${min.toFixed(2)}`.replace('.', '')
      },
      max: {
        amount: max,
        amountInMinorUnits: `${max}`.replace('.', '')
      }
    }
  };
  return {
    loading: false,
    data: filters
  };
};
