import { isEmpty } from 'lodash';

// UTILS - FALLBACK VALUES
export const fallbackValues = (loading, isTemplate, data, fallback) => {
  if (loading) {
    return data;
  }

  if (isTemplate) {
    return fallback;
  }
  return isEmpty(data) ? fallback : data;
};

export const fallbackPagesValues = (loading, isTemplate, obj, fallback) => {
  if (loading) {
    return obj;
  }
  if (isTemplate) {
    return fallback;
  }
  return isEmpty(obj.data) ? fallback : obj;
};
