const DEFAULT_FOOTER_NAVIGATION_LINK_A = {
  id: 1,
  name: 'Company',
  description: 'All about us as a company and what to know',
  links: [
    {
      id: 1,
      name: 'Support',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 2,
      name: 'Contact us',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 3,
      name: 'Find a store',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 4,
      name: 'About us',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 5,
      name: 'Special offers',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 6,
      name: 'Global Press Room',
      navigation: {
        type: null,
        path: null
      }
    }
  ]
};

const DEFAULT_FOOTER_NAVIGATION_LINK_B = {
  id: 2,
  name: 'Legal',
  description: 'All about us as a company and what to know',
  links: [
    {
      id: 1,
      name: 'Privacy policy',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 2,
      name: 'Terms of use',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 2,
      name: 'Returns Policy',
      navigation: {
        type: null,
        path: null
      }
    }
  ]
};

export const DEFAULT_FOOTER_NAVIGATION_LINKS = [
  { ...DEFAULT_FOOTER_NAVIGATION_LINK_A },
  { ...DEFAULT_FOOTER_NAVIGATION_LINK_B }
];

export const DEFAULT_HEADER_NAVIGATION_LINK = {
  id: 1,
  name: 'Company',
  description: 'All about us as a company and what to know',
  links: [
    {
      id: 1,
      name: 'Home',
      navigation: {
        type: 'SYSTEM_PAGE',
        path: 'home'
      }
    },
    {
      id: 2,
      name: 'Headphones',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 3,
      name: 'Speakers',
      navigation: {
        type: null,
        path: null
      }
    },
    {
      id: 4,
      name: 'New Releases',
      navigation: {
        type: null,
        path: null
      }
    }
  ]
};
