// material
import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const NavigationText = ({ children, isActive, textColour, primaryColour, sx }) => {
  const theme = useTheme();
  const activeColour = primaryColour || theme.palette.primary.main;
  const colour = isActive ? activeColour : textColour;
  return (
    <Typography variant="body2" color={colour} {...sx}>
      {children}
    </Typography>
  );
};
