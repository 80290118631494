import { random } from 'lodash';
import { paramCase } from 'change-case';
import initial from './initial';

const DEFAULT_COLLECTION_NAME = 'Collection Name';
const DEFAULT_COLLECTION_DESCRIPTION = `<p class="ql-align-center"><strong style="color: rgb(126, 126, 126); font-family: Gaegu; font-size: 20px;">A short description of the collection highlighting what your customer can expect</strong></p>`;

const createCollectionsList = (size) =>
  [...Array(size)].map((_, index) => ({
    id: index,
    name: DEFAULT_COLLECTION_NAME,
    resource: paramCase(DEFAULT_COLLECTION_NAME),
    image: null,
    description: DEFAULT_COLLECTION_DESCRIPTION,
    count: random(1, 100, false)
  }));

export const DEFAULT_COLLECTION = createCollectionsList(1)[0];

export const getDefaultPagedCollections = (size) => {
  const collections = createCollectionsList(size);
  return {
    ...initial.pagableData,
    data: collections,
    total: collections.length
  };
};

export const getDefaultCollections = (size) => createCollectionsList(size);
