// material
import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export const DrawerActionButton = ({
  isMobile,
  enabled = true,
  primaryColour,
  logoPosition,
  isNavigationActive,
  handleDrawerToggle
}) => {
  const isLeft = logoPosition === 'LEFT';
  const startItemStyle = {
    ...(isLeft && enabled && { mr: 1 }),
    ...(!isLeft && { flex: '1 0 0' }),
    justifyContent: 'start'
  };

  return isNavigationActive && enabled ? (
    <Box
      sx={{
        ...startItemStyle,
        alignSelf: 'center',
        ...(primaryColour && { color: primaryColour })
      }}
    >
      <IconButton color="inherit" aria-label="open" edge="start" onClick={handleDrawerToggle} disabled={!isMobile}>
        <MenuIcon />
      </IconButton>
    </Box>
  ) : (
    <Box sx={{ ...startItemStyle }} />
  );
};
