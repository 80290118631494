// material
import React, { useEffect, useState, useCallback } from 'react';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import { AppBar, Box } from '@material-ui/core';
import { defaultTo, isEmpty } from 'lodash';
import useOffSetTop from '../../hooks/useOffSetTop';
import {
  CURRENCY_SELECTOR_VIEW_POSITION,
  HEADER_APPEARANCE,
  HEADER_APPEARANCE_FILL,
  NAVIGATION_VIEW_TYPE
} from '../../utils/constants';
import useMobile from '../../hooks/useMobile';
import { createTabValue } from '../../utils/pageTypes';
import { CompactHeader, HeaderMobileView, HeaderMobileDrawer, StandardHeader } from './header-components';

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('all'),
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main
}));

const INIT_HEADER_DATA = {
  id: '',
  loading: false,
  data: null
};

export default function Header(props) {
  const { isPreview, capability, containerRef, config, currentNavigation, handleGetNavigation } = props;
  const isMobile = useMobile();
  const { currency: currencyCapability } = capability;
  const { showAnnouncement, announcementHeight, header, appearance, currency: currencyConfig } = config;
  const {
    appearance: headerAppearance,
    appearanceFill: headerAppearanceFill,
    enableNavigation,
    navigationLinkId,
    backgroundColour,
    textColour: textColourRoot,
    primaryColour,
    secondaryColour,
    secondaryContrastColour,
    mobileBackgroundColour,
    mobileTextColour: mobileTextColourRoot,
    logoPosition,
    logoPositionMobile,
    navigationPosition,
    viewType,
    navigationViewType,
    showLogo,
    showDivider,
    enableSearch,
    enableCartButton,
    enableMaxNavigationSize,
    maxNavigationSize
  } = header;
  const isOffsetTop = useOffSetTop(0);
  const theme = useTheme();
  const textColour = textColourRoot || theme.palette.text.secondary;
  const mobileTextColour = mobileTextColourRoot || theme.palette.text.secondary;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navigation, setNavigation] = useState(INIT_HEADER_DATA);
  const currentTabValue = currentNavigation && createTabValue(currentNavigation.type, currentNavigation.path);
  const isNavigationActive = Boolean(navigation.data && !isEmpty(navigation.data?.links));

  // Appearance
  const isStickyAppearance = headerAppearance === HEADER_APPEARANCE[1] || headerAppearance === HEADER_APPEARANCE[3];

  // Only true for desktop
  const isStandardView = Boolean(!isMobile && viewType === NAVIGATION_VIEW_TYPE[0]);
  const isCompactView = Boolean(!isMobile && viewType === NAVIGATION_VIEW_TYPE[1]);
  const isCurrencyTopView = currencyConfig?.sidebar?.verticalPosition === CURRENCY_SELECTOR_VIEW_POSITION[1];

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, [setMobileOpen]);

  const componentProps = {
    // Config
    enableNavigation,
    appearance,
    currencyCapability,
    currencyConfig,
    logoPositionMobile,
    logoPosition,
    navigationPosition,
    navigationViewType,
    showLogo,
    showDivider,
    enableSearch,
    enableCartButton,
    enableMaxNavigationSize,
    maxNavigationSize,

    // Header props
    isPreview,
    isMobile,
    primaryColour,
    secondaryColour,
    secondaryContrastColour,
    textColour,
    mobileBackgroundColour,
    mobileTextColour,
    open: mobileOpen,
    navigation,
    currentTabValue,
    isNavigationActive,
    isCurrencyTopView,
    isStandardView,
    isCompactView,
    handleDrawerToggle,

    // common props
    ...props
  };

  const getAnnoucementHeight = useCallback(() => {
    if (showAnnouncement && !isPreview && isStickyAppearance) {
      return announcementHeight;
    }
    return 0;
  }, [isPreview, isStickyAppearance, showAnnouncement, announcementHeight]);

  const getBackgroundFillStyle = useCallback(() => {
    const isNonStandard = headerAppearance !== HEADER_APPEARANCE[0];
    const isScrollFill = headerAppearanceFill === HEADER_APPEARANCE_FILL[2];
    const isScrollTranslucent = headerAppearanceFill === HEADER_APPEARANCE_FILL[3];
    const isScrollOnlyStyle = isScrollFill || isScrollTranslucent;

    const transparentStyle = {
      backgroundColor: 'transparent',
      backdropFilter: 'none',
      WebkitBackdropFilter: 'none'
    };
    const translucentStyle = {
      backgroundColor: isEmpty(backgroundColour) ? 'transparent' : alpha(backgroundColour, 0.5)
    };
    const fillBackgroundStyle = { ...(!isEmpty(backgroundColour) && { backgroundColor: backgroundColour }) };
    return {
      // Default
      ...fillBackgroundStyle,
      // Non-Standard - All except standard can have Transparent / Translucent
      ...(isNonStandard && {
        // Transparent
        ...(headerAppearanceFill === HEADER_APPEARANCE_FILL[0] && transparentStyle),
        // Translucent
        ...(headerAppearanceFill === HEADER_APPEARANCE_FILL[1] && translucentStyle)
      }),
      // Only sticky can have fill on scroll
      ...(isStickyAppearance &&
        isScrollOnlyStyle && {
          // Scroll only
          ...(isOffsetTop && !isPreview
            ? {
                ...(isScrollFill && fillBackgroundStyle),
                ...(isScrollTranslucent && translucentStyle)
              }
            : transparentStyle),
          // Preview does not show scroll we always default to start point (transparent)
          ...(isPreview && transparentStyle)
        })
    };
  }, [isOffsetTop, isPreview, isStickyAppearance, headerAppearance, headerAppearanceFill, backgroundColour]);

  const getPreviewOrDefault = useCallback(() => {
    const preview = defaultTo(isPreview, false);
    // Overlap Sticky
    if (preview && (headerAppearance === HEADER_APPEARANCE[1] || headerAppearance === HEADER_APPEARANCE[3])) {
      // Overlap
      return HEADER_APPEARANCE[2];
    }
    return headerAppearance;
  }, [headerAppearance, isPreview]);

  const getAppearanceStyle = useCallback(() => {
    // In preview theres a limitation to view sticky so we revert to absolute positioning
    const selected = getPreviewOrDefault();
    const isSticky = selected?.includes('STICKY');
    return {
      // Overlap
      ...(selected === HEADER_APPEARANCE[2] && {
        position: 'absolute',
        width: '100%',
        zIndex: 100
      }),
      // Overlap / Standard Sticky
      ...(isSticky && {
        position: 'fixed',
        width: '100%',
        zIndex: 100,
        top: 0
      }),
      ...getBackgroundFillStyle()
    };
  }, [getBackgroundFillStyle, getPreviewOrDefault]);

  const getNavigationData = useCallback(() => {
    const isNavigationReady = !navigation.loading && navigationLinkId !== navigation.id;
    if (isNavigationReady) {
      setNavigation({ ...INIT_HEADER_DATA, loading: true });
      handleGetNavigation(navigationLinkId)
        .then((_navigation) => {
          setNavigation({
            id: navigationLinkId,
            data: _navigation,
            loading: false
          });
        })
        .catch(() => setNavigation({ id: navigationLinkId, loading: false }));
    }
  }, [navigationLinkId, navigation, handleGetNavigation]);

  useEffect(() => {
    getNavigationData();
  }, [getNavigationData]);

  return (
    <Box>
      <RootStyle position="relative" sx={{ ...getAppearanceStyle(), pt: `${getAnnoucementHeight()}px` }}>
        {isMobile && <HeaderMobileView {...componentProps} />}
        {isStandardView && <StandardHeader {...componentProps} />}
        {isCompactView && <CompactHeader {...componentProps} />}
      </RootStyle>

      {Boolean(isMobile && enableNavigation) && <HeaderMobileDrawer containerRef={containerRef} {...componentProps} />}
    </Box>
  );
}
