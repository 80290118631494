// material
import React, { useMemo } from 'react';
import { Box, Stack } from '@material-ui/core';
import { DrawerActionButton } from './DrawerActionButton';
import { HeaderButtons } from './HeaderButtons';
import { HeaderLogo } from './HeaderLogo';

export const StandardHeaderBar = (props) => {
  const {
    enableNavigation,
    isStandardView,
    logo,
    isMobile,
    cartTotal,
    primaryColour,
    secondaryColour,
    secondaryContrastColour,
    logoPosition,
    showLogo,
    enableSearch,
    enableCartButton,
    isNavigationActive,
    handleNavigationClick,
    handleDrawerToggle
  } = props;

  const DrawerActionButtonMemo = useMemo(
    () => (
      <DrawerActionButton
        isMobile={isMobile}
        primaryColour={primaryColour}
        enabled={enableNavigation}
        logoPosition={logoPosition}
        isNavigationActive={isNavigationActive}
        handleDrawerToggle={handleDrawerToggle}
      />
    ),
    [isMobile, enableNavigation, primaryColour, logoPosition, isNavigationActive, handleDrawerToggle]
  );
  const LogoMemo = useMemo(
    () => (
      <HeaderLogo
        isMobile={isMobile}
        enableNavigation={enableNavigation}
        isStandardView={isStandardView}
        logoPosition={logoPosition}
        logo={logo}
        handleNavigationClick={handleNavigationClick}
      />
    ),
    [isMobile, enableNavigation, isStandardView, logoPosition, logo, handleNavigationClick]
  );
  const ActionButtons = useMemo(
    () => (
      <HeaderButtons
        isMobile={isMobile}
        cartTotal={cartTotal}
        primaryColour={primaryColour}
        secondaryColour={secondaryColour}
        secondaryContrastColour={secondaryContrastColour}
        logoPosition={logoPosition}
        enableCartButton={enableCartButton}
        enableSearch={enableSearch}
        handleNavigationClick={handleNavigationClick}
      />
    ),
    [
      isMobile,
      cartTotal,
      primaryColour,
      secondaryColour,
      secondaryContrastColour,
      logoPosition,
      enableCartButton,
      enableSearch,
      handleNavigationClick
    ]
  );

  const StandardHeaderBarMemo = useMemo(() => {
    return (
      <Stack spacing={0} direction="row" justifyContent="space-between" sx={{ width: '100%', height: '100%' }}>
        {isMobile && DrawerActionButtonMemo}
        {showLogo ? LogoMemo : <Box flexGrow={1} />}
        {ActionButtons}
      </Stack>
    );
  }, [showLogo, isMobile, DrawerActionButtonMemo, LogoMemo, ActionButtons]);

  return StandardHeaderBarMemo;
};
